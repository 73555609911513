
jQuery(document).ready(($) => {
    $('.search-box').css({
        visibility: 'hidden',
        opacity: '0',
        zIndex: 3000,
    });
    
    $('#searchDesktop, #searchMobile').on('click', (e) => {
        e.preventDefault()
        $('.search-box').css({
            visibility: 'visible',
            opacity: '1',
            display: 'block'
        })
    })
    
    $('#search-close').on('click', (e) => {
        e.preventDefault()
        $('.search-box').css({
            visibility: 'hidden',
            opacity: '0'
        });
    })
})