// import scrollMagic from 'scrollmagic'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

var modules = [
    '.content-image-callout',
    '.content-3-image-blocks',
    '.split-image-detail',
    '.content-split-left',
    '.content-split-left-slider',
    '.content-split-right',
    '.content-split-right-slider',
    '.shop-callout',
    '.event-block',
    '.content-scroll-slider',
    '.content-3-image-callout',
    '.content-split-image-callout',
    '.content-block',
    '.image-callouts',
    '.content-links',
    '.gallery',
    '.feature-slider',
    '.instagram-block',
    '.text-callout',
    '.vineyards-map',
    '.split-paragraphs',
    '.split-shop-callouts',
    '.newsletter-form',
    '.bottle-features',
    '.icon-info-toggle',
    '.wine-club-callouts',
    '.split-image-content',
    '.gift-callouts',
    '.contact-form',
    '.tripleseat-form',
    '.wine-club-contact',
]

var calculatedModules = []

function getModulesIndex(module) {
    var moduleId = '#' + module.slice(1) + '-'

    $(module).each((index, obj) => {
        calculatedModules.push(moduleId + index)
    })
}

function isFirstModule(module) {
    var firstModule = $('.modules .grid-container').first(),
        compareModule = $(module)

    if (firstModule.is(compareModule)) {
        return true
    } else {
        return false
    }
}

// Init Calculate Modules
new Promise((resolve, reject) => {
    modules.forEach((module) => { 
        getModulesIndex(module)
    })
    resolve()
})
// Init Animations
.then(() => {
    calculatedModules.forEach((module) => {
        // Run animation if not the first module on load
        if (!isFirstModule(module)) {
            new ScrollMagic.Scene({
                triggerElement: module
            })
            .setTween(
                TweenMax.to(module + ' .anim', .5, 
                    {opacity: 1}
                )
            )
            .addTo(
                new ScrollMagic.Controller({
                    refreshInterval: 50,
                    globalSceneOptions: {
                        triggerHook: "onEnter", 
                        duration: "80%",
                        offset: 200
                    }
                })
            )
        } else {
            $(module).children('div').each((index, obj) => {
                $(obj).removeClass('anim')
            })
        }
    })
})
.catch((error) => {
    console.log(error)
})