document.addEventListener("DOMContentLoaded", function () {
    const shop_menu_trigger = document.querySelector('.shop-menu-trigger'),
        menu_items = document.querySelectorAll('.menu-item'),
        shop_menu = document.querySelector('.shop-menu'),
        mobile = window.matchMedia( "(max-width: 1023px)" )

    let shopMenuOpen = false

    if (!mobile.matches) {
        shop_menu_trigger.addEventListener('mouseover', (e) => {
            e.preventDefault();
            shopMenuOpen = true
            shop_menu.classList.add('shop-menu-active')
            shop_menu_trigger.classList.add('shop-menu-trigger-active')
        })

        shop_menu.addEventListener('mouseleave', (e) => {
            e.preventDefault()
            shop_menu.classList.remove('shop-menu-active')
            shop_menu_trigger.classList.remove('shop-menu-trigger-active')
        })

        menu_items.forEach((obj, index) => {
            obj.addEventListener('mouseover', () => {
                if (!obj.classList.contains('shop-menu-trigger')) {
                    shop_menu.classList.remove('shop-menu-active')
                    shop_menu_trigger.classList.remove('shop-menu-trigger-active')
                }
            })
        })
    }
})