import {ResizeObserver as Polyfill} from '@juggle/resize-observer'

const ResizeObserver = window.ResizeObserver || Polyfill
const menuItem = 

jQuery(document).ready(($) => {

    $('#menu-menu-left li').each((index, obj) => {
        $(obj).removeClass('active')
    })

     // Add class for nested sub-menus
     $('.menu-item-has-children').each((index, obj) => {
        $(obj).find('.sub-menu').find('.sub-menu').addClass('nested-sub-menu')
        $(obj).find('.nested-sub-menu').find('.sub-menu').find('a').addClass('nested-sub-menu-anchor')
    })

 
    const ro = new ResizeObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.contentRect.width >= 1024) {
                // Biodynamics & Green Farming
                setSubMenuHover('#menu-item-93511')

                setSubMenuChildHover('#menu-item-93511', '#menu-item-93527')

                // Wine Clubs
                setSubMenuHover('#menu-item-93506')

                // Visit Us
                setSubMenuHover('#menu-item-93507')

                // Our Story
                setSubMenuHover('#menu-item-94279')

                // Events
                setSubMenuHover('#menu-item-95049')

                // Corporate Gifting
                setSubMenuHover('#menu-item-94766')

                // Shop Wines
                setSubMenuHover('#menu-item-94485')
                setSubMenuChildHover('#menu-item-94485', '#menu-item-95689')
            
            } 
            else if (entry.contentRect.width < 1024) {
                $('.menu-item-has-children').each((index, item) => {
                    $(item).children('.sub-menu').addClass('hide')
        
                    $(item).not('.anchor-heading').children('a').on('click', (e) => {
                        e.preventDefault()
                        if (!$(item).hasClass('sub-active')) {
                            setActive('.menu-item-has-children', item)
                        } else {
                            $(item).removeClass('sub-active')
                            $(item).children('.sub-menu').addClass('hide')
                        }
                    })
                });
                $('#menu-item-93527 > a').click(function(e) {
                    e.preventDefault();
                    $('.menu-item-93511 > .sub-menu').toggleClass('hide');
                    $('.menu-item-93527 > .sub-menu').toggleClass('hide');
                    $('.menu-item-93527 > .sub-menu').removeClass('nested-sub-menu');  
                });
            }
        })
      })
      ro.observe(document.body); // Watch dimension changes on body


    function setSubMenuHover(menuID) {
        var subMenu = $(menuID).children('.sub-menu').first()

        $(menuID).on('mouseover', function (e) {
            e.preventDefault()
            subMenu.show()
            subMenu.css({
                visibility: 'visible'
            })
        })
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault()
            })
            .on('mouseleave', function (e) {
                e.preventDefault()
                $('.sub-menu').hide()
            })
        $('.menu-item').not(menuID).on('mouseenter', function (e) {
            e.preventDefault()
            $(menuID).children('.sub-menu').hide()
        })
    }


    function setSubMenuChildHover(parentID, menuID) {
        var childMenuID = $(parentID).find(menuID),
            subMenu = childMenuID.children('.sub-menu')

        childMenuID.on('mouseover', function (e) {
            e.preventDefault()
            subMenu.show()
            subMenu.css({
                visibility: 'visible'
            })
        })
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault()
            })
            .on('mouseleave', function (e) {
                e.preventDefault()
                $('.sub-menu').hide()
            });
        $('.menu-item').not(childMenuID).on('mouseenter', function (e) {
            e.preventDefault()
            $(childMenuID).children('.sub-menu').hide()
        })
    }


    function setActive(elementsClass, activeElement) {
        new Promise((resolve, reject) => {
            $(elementsClass).each((index, el) => {
                $(el).children('.sub-menu').addClass('hide')
                $(el).removeClass('sub-active')
            })
            resolve()
        })
        .then(() => {
            $(activeElement).addClass('sub-active')
            $(activeElement).children('.sub-menu').removeClass('hide')
        })
        .catch((error) => console.log(error))
    }
})