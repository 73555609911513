import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import $ from 'jquery'

$(document).ready(() => {
    var timelineImageSwiper = new swiper('.timeline-image-container', {
        direction: 'vertical',
        loop: false,
        speed: 1600,
        spaceBetween: 10
    })
    timelineImageSwiper.init()

    var timelineContentSwiper = new swiper('.timeline-content-container', {
        direction: 'vertical',
        loop: false,
        speed: 1600,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                var year = document.querySelectorAll('.timeline-content-container .swiper-slide')[index].getAttribute('data-year')
                return '<span class="' + className + '">' + year + '</span>'
            }
        },
        breakpoints: {
            // when window width is >= 320px
            640: {
                speed: 2000,
            },
          }
    })
    timelineImageSwiper.init()

    timelineContentSwiper.on('transitionStart', (e) => {
        timelineImageSwiper.slideTo(timelineContentSwiper.activeIndex)
    })
})
