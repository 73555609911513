document.addEventListener("DOMContentLoaded", function () {
    
    const vineyard_ids = [
        '#bella_luna',
        '#de_coelo',
        '#benziger',
        '#sunny_slope'
    ]

    const vineyards = document.querySelectorAll(vineyard_ids.toString())

    vineyards.forEach((obj, index) => {
        let name = obj.dataset.name,
            image = document.querySelector('img[data-vineyard="' + name + '"]'),
            vineyardURL = image.dataset.vineyardLink

        obj.addEventListener('click', (e) => {
            e.preventDefault()

            window.location = vineyardURL
        })

        obj.addEventListener('mouseover', (e) => {
            e.preventDefault()

            new Promise((resolve, reject) => {
                removeAllActiveVineyards()
                resolve()
            })
            .then(() => {
                setVineyardActive(image)
            })
            .catch((error) => console.log(error))
        })
    })

    function setVineyardActive(image) {
        image.classList.add('show-vineyard-image')
    }
    
    function removeAllActiveVineyards() {
        let allActiveImages = document.querySelectorAll('.vineyard-image')

        allActiveImages.forEach((obj, index) => {
            var isActive = obj.classList.contains('show-vineyard-image')
            if (isActive) obj.classList.remove('show-vineyard-image')
        })
    }
})
