// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import 'foundation-sites'

// import JS modules to compile
import './init-foundation'
import './wp-foundation'
// import './modules/newsletter'
import './modules/fancybox'
import './modules/store-locator'
import './modules/navMenu'
import './modules/submenu'
import './modules/sliders'
import './modules/modules'
import './modules/gallery'
import './modules/search'
import './modules/animations'
import './modules/contact'
import './modules/ie'
import './modules/banner'
import './modules/wineClubFeatureBlock'
import './modules/accordion'
import './modules/scrollSlider'
import './modules/popup'
import './modules/timeline'
import './modules/vineyards'
import './modules/iconToggle'
import './modules/mailingList'
import './modules/shopMenu'
import './modules/formSelect'
import './modules/cancelForm'