import g from './globals'

if (g.elementInDom('#wpcf7-f95133-p95136-o1')) {
  var suspend = document.querySelector('input[name="suspend-accept[]"]'),
      cancel = document.querySelector('input[name="cancel-accept[]"]'),
      suspendComments = document.getElementById('suspend-comments'),
      cancelComments = document.getElementById('cancel-comments'),
      suspendDates = document.getElementsByName('suspend-dates[]'),
      cancelDates = document.getElementsByName('cancel-reason[]')

  suspend.addEventListener('click', (e) => {
    if (e.currentTarget.checked) {
      suspendComments.classList.add('wpcf7-validates-as-required')
      disableOptions(cancel, cancelComments, cancelDates)
    }
  })

  cancel.addEventListener('click', (e) => {
    if (e.currentTarget.checked) {
      cancelComments.classList.add('wpcf7-validates-as-required')
      disableOptions(suspend, suspendComments, suspendDates)
    }
  })

  function disableOptions(name, comments, dates) {
    name.checked = false
    comments.classList.remove('wpcf7-validates-as-required')
    comments.value = ''
    
    dates.forEach(date => {
        date.checked = false
    });
  }
}