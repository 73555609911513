import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

const feature_sliders = document.querySelectorAll('.feature-slider-container')

feature_sliders.forEach((obj) => {
    new swiper(obj, {
      direction: 'horizontal',
      autoHeight: true,
      loop: false,
      effect: 'fade',
      autoplay: {
        delay: 5000,
      },
      
      // If we need pagination
      pagination: {
        el: obj.querySelector('.feature-slider-controls'),
        clickable: true,
      },

      on: {
        slideChangeTransitionEnd: () => {
            if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
                $('.feature-image-container').toggleClass('video-slider-height');
            } else {
              $('.feature-image-container').removeClass('video-slider-height');
            }
        },
        init: () => {
          if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
              $('.feature-image-container').addClass('video-slider-height');
          } else {
            $('.feature-image-container').removeClass('video-slider-height');
          }
        }
      }
  })
})

// Featured Wines Slider
const content_slider = new swiper('.swiper-container', {
  direction: 'horizontal',
  autoHeight: false,
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 22,
      slidesOffsetBefore: -10
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 25
    },
    1440: {
      slidesPerView: 4,
      spaceBetween: 25
    }
  },
  autoplay: {
    delay: 3000,
  },
  centeredSlides: false,
  loop: jQuery(".swiper-container .swiper-slide").length != 1,
  watchOverflow: true, 
  navigation: {
    prevEl: '.swiper-previous',
    nextEl: '.swiper-next',
  },
});


// 3 Boxes Slider
var events_content_slider = new swiper('.swiper-container-boxes', {
  direction: 'horizontal',
  autoHeight: false,
  loop: true,
  watchOverflow: true,
  effect: 'slide',
  // slidesPerView: 5,
  // spaceBetween: 10,
  breakpoints: {
    '@0.00': {
      slidesPerView: 1.1,
      spaceBetween: 25,
      slidesOffsetBefore: -16
    },
    '@0.50': {
      slidesPerView: 1.1,
      spaceBetween: 25,
      slidesOffsetBefore: -16
    },
    '@0.70': {
      slidesPerView: 1.1,
      spaceBetween: 25,
      slidesOffsetBefore: -16
    },
    '@0.80': {
      slidesPerView: 1.1,
      spaceBetween: 25,
      slidesOffsetBefore: -16
    },
    '@0.85': {
      slidesPerView: 3,
      spaceBetween: 25
    },
    '@0.90': {
      slidesPerView: 3,
      spaceBetween: 25
    },
    '@1.00': {
      slidesPerView: 3,
      spaceBetween: 25
    },
    '@1.25': {
      slidesPerView: 3,
      spaceBetween: 25
    },
    '@1.50': {
      slidesPerView: 3,
      spaceBetween: 25
    }
  },
  centeredSlides: true,
  initialSlide: 1,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-previous',
  },
});
