var navTriggers = document.querySelectorAll('.nav-trigger'),
    detailTriggers = document.querySelectorAll('.detail-trigger')

navTriggers.forEach((obj, index) => {
    obj.addEventListener('click', (e) => {
        e.preventDefault()

        const club = obj.dataset.club

        new Promise((resolve, reject) => {
            removeAllDetails(resolve)
        })
            .then(() => {
                obj.classList.add('nav-trigger-active')
                document.querySelector('.detail-trigger[data-club="' + club + '"]').classList.remove('hide')
            })
            .catch((error) => {
                console.log(error)
            })
    })
})

function removeAllDetails(resolve) {
    detailTriggers.forEach((obj, index) => {
        obj.classList.add('hide')
    })
    navTriggers.forEach((obj, index) => {
        obj.classList.remove('nav-trigger-active')
    })
    resolve()
}

