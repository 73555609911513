import {Printd} from 'printd'

$(document).ready(() => {
    // Content & Detail
    $('.detail p').css('margin-bottom', '0')
    $('.detail em').parent('p').first().css('margin-top', '1rem')
    $('.detail em').parent('p').css('margin-bottom', '1rem')
    $('.detail strong').parent('p').css('margin-top', '2rem')


    $('.sbi-owl-nav .sbi-owl-prev').each((index, obj) => {
        $(obj).html('<img src="<?php echo get_template_directory_uri(); ?>/assets/images/arrow-right.png" alt="arrow image">')
    })
    $('.sbi-owl-nav .sbi-owl-next').each((index, obj) => {
        $(obj).html('<img src="<?php echo get_template_directory_uri(); ?>/assets/images/arrow-left.png" alt="arrow image">')
    })
    

    // Content Split
    var contentSplitCount,
        contentSplitElements = []

    new Promise((resolve, reject) => {
        $('.content-split, .split-image-content, .split-image-detail').each((index, obj) => {
            contentSplitCount += index
            contentSplitElements.push(obj)
        })
        resolve()
    })
    .then(() => {
        if (contentSplitElements.length === 1) {
            $(contentSplitElements[0]).addClass('only-module-padding')
        } 
        else {
            var first = contentSplitElements[0],
                last = contentSplitElements.slice(-1)[0]
            $(first).addClass('first-module-padding')
            $(last).addClass('last-module-padding')

            // Add class for mobile padding adjustments
            $(contentSplitElements).each((index, obj) => {
                $(obj).addClass('multiple-content-split-modules')
            })
        }
    })
    .catch((error) => {
        console.log(error)
    })


    // Content Split slider
    var contentSplitSliderCount,
        contentSplitSliderElements = []

    new Promise((resolve, reject) => {
        $('.content-split-slider').each((index, obj) => {
            contentSplitSliderCount += index
            contentSplitSliderElements.push(obj)
        })
        resolve()
    })
    .then(() => {
        if (contentSplitSliderElements.length === 1) {
            $(contentSplitSliderElements[0]).addClass('only-slider-padding')
        } 
        else {
            var first = contentSplitSliderElements[0],
                last = contentSplitSliderElements.slice(-1)[0]
            $(first).addClass('first-slider-padding')
            $(last).addClass('last-slider-padding')

            // // Add class for mobile padding adjustments
            // $(contentSplitSliderElements).each((index, obj) => {
            //     $(obj).addClass('multiple-content-split-modules')
            // })
        }
    })
    .catch((error) => {
        console.log(error)
    })




    $('#printMe').on('click', (e) => {
        e.preventDefault()

        const cssText = `
            .feature-slider,
            #content-block-0,
            footer,
            header,
            .social {
                display: none !important;
            }
            .main-content .heading-1 {
                font-size: 40px !important;
            }
            .main-content p {
                font-size: 20px;
                padding-bottom: 2rem;
            }
            .heading-1 {
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 32px;
                line-height: 1;
                display: inline-block;
                padding-bottom: 1rem;
                margin: 0;
                text-rendering: optimizeLegibility;
            }
            .heading-2 {
                text-transform: uppercase;
                font-family: alternate-gothic-no-2-d,sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                letter-spacing: 2px;
                text-rendering: optimizeLegibility;
            }
            ul li {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                text-rendering: optimizeLegibility;
            }

            ul li span {
                font-family: alternate-gothic-no-2-d, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                letter-spacing: 2px;
                text-rendering: optimizeLegibility;
                color: #aaa086;
                text-transform: uppercase;
                display: inline-block;
                width: 130px;
            }
            ul li div {
                display: inline-block;
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 1.2 !important;
                text-rendering: optimizeLegibility;
            }
        `

        const d = new Printd()
        d.print(document.querySelector('.modules'), [ cssText ] )
    })

})






