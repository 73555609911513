import $ from 'jquery'

$('.icon-info-toggle').each((index, parentObj) => {
    $('.certification-logos img').each((index, obj) => {
        let certId = $(obj).attr('data-cert-id')
        
        $(obj).parent('.logo-wrapper').on('click', (e) => {
            e.preventDefault()
            removeVisibleCert(certId)
        })
        
    })
})

function removeVisibleCert(certId) {
    new Promise((resolve, reject) => {
        $('.content-toggle .certification').each((index, obj) => {
            $(obj).addClass('hide')
        })
        $('.certification-logos .logo-wrapper').each((index, obj) => {
            $(obj).removeClass('logo-active')
            $(obj).find('.toggle-identifier span').html('+')
        })
        resolve()
    })
    .then(() => {
        $('#certification-' + certId).removeClass('hide')
        $('#logo-' + certId).addClass('logo-active')
        $('#logo-' + certId).find('.toggle-identifier span').html() == "+" ? $('#logo-' + certId).find('.toggle-identifier span').html('-') : $('#logo-' + certId).find('.toggle-identifier span').html('+');
    })
    .catch((error) => { 
        console.log(error)
    })
}