import scrollMagic from 'scrollmagic'
import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)
const $win = window


$(document).ready(() => {

    $('.content-split-left-slider-container').each((index, obj) => {
        var split_scroll_slider = new swiper(obj, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            centeredSlides: true,
            watchOverflow: true,
            pagination: {
                el: '.feature-slider-controls',
                clickable: true,
              },
        })
    })

    $('.content-split-right-slider-container').each((index, obj) => {
        var split_scroll_slider = new swiper(obj, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            centeredSlides: true,
            watchOverflow: true,
            pagination: {
                el: '.feature-slider-controls',
                clickable: true,
              },
        })
    })

    $('.scrollSlider').each((index, obj) => {

        var slideWidth = $('#scrollSlider-' + index + ' .scrollSlide-' + index).width(),
            slideLength = $('#scrollSlider-' + index + ' .scrollSlide-' + index).length,
            sliderWidth = slideWidth * $('#scrollSlider-' + index + ' .scrollSlide-' + index).length,
            sliderDuration = Math.ceil(sliderWidth)

        var scroll_slider = new swiper('#scrollSliderContainer-' + index, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            centeredSlides: true,
            watchOverflow: true,
            navigation: {
                nextEl: '.slider-nav-next-' + index,
                prevEl: '.slider-nav-prev-' + index,
            },
        })

        var scroll_slider = new swiper('#author-slider' + index, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            watchOverflow: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: '.slider-nav-next-' + index,
                prevEl: '.slider-nav-prev-' + index,
            },
        })
        
        // for (var i = 0; i < scrollSlideLength; i++) {
        //     slideAnimation.to(".scrollSlide-" + index, 2, tlParams)
        // } 
    

    })
})


// $(document).ready(() => {
//     if (!md.mobile()) {
//         $('.scrollSlider').each((index, obj) => {
//             var mouseScroll;

//             $('#scrollSlider-' + index).on('wheel', (event) => {
//               mouseScroll = event.originalEvent.deltaY  
//             })

//             var scroll_slider = new swiper('#scrollSlider-' + index, {
//                 direction: 'horizontal',
//                 slidesPerView: 1,
//                 spaceBetween: 10,
//                 centeredSlides: true,
//                 mousewheel: true,
//                 on: {
//                     transitionEnd : () => {
//                         if (scroll_slider.isEnd) {
//                             console.log(mouseScroll)
//                             setTimeout(() => {
//                                 var offset = $('#scrollSlider-' + index).closest('.grid-container').next().offset()
//                                 offset.top += 70
//                                 $('html, body').animate({
//                                     scrollTop: offset.top,
//                                 });
//                             }, 500)
//                             setTimeout(() => {
//                                 scroll_slider.slideTo(0)
//                                 scroll_slider.mousewheel.disable()
//                             }, 500)
//                         }
//                     }
//                 }
//             })   
//         })
//     }
//     if (md.mobile()) {
//         $('.scrollSlider').each((index, obj) => {

//             var scroll_slider = new swiper('#scrollSlider-' + index, {
//                 direction: 'horizontal',
//                 slidesPerView: 1,
//                 spaceBetween: 10,
//                 centeredSlides: true,
//             })
    
//         })
//     }
    
// })